import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import slides from '../slides';
import Fraud from '../../img/fraud.mp4';
import Teaser from '../../img/Teaser.mp4';
import Modal from 'react-modal';
import YouTube from 'react-youtube';


Modal.setAppElement('#root');

const VideoPlayer = ({ videoId }) => {
  const opts = {
    height: '320',
    width: '100%',
    playerVars: {
      autoplay: 1,
      mute: 1,
      modestbranding: 1,
      rel: 0,
    },
  };

  const handlePlayerReady = (event) => {
    const player = event.target;
    player.setPlaybackQuality('small'); 
  };

  console.log("Video ID:", videoId);

  return <YouTube videoId={videoId} opts={opts} />;
};

const VideoPlayerSlide = ({ videoId }) => {
  const iframeStyle = {
    width: "100%",
    height: "900px",
    border: "none",
  };

  return (
    <iframe
      src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=0&modestbranding=1&rel=0&controls=1&showinfo=0`}
      title="YouTube Video Slide Player"
      style={iframeStyle}
      allow="autoplay; encrypted-media"
      allowFullScreen
    ></iframe>
  );
};
  
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", transform: 'scale(2)', right: '5%', color: '#000', zIndex: '5' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", transform: 'scale(2)', left: '4%', color: '#000', zIndex: '5' }}
      onClick={onClick}
    />
  );
}
  
const Carousel = ({ selectedSlide }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const sliderRef = useRef(null);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  }

  useEffect(() => {
    sliderRef.current.slickGoTo(selectedSlide);
  }, [selectedSlide]);

  const settings = {
    easing: 'linear',
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const videoId1 = '976wwoTyois';
  const videoId2 = 'EAo9qsc1u4U';
  

  

  return (
    <>
    <Slider {...settings} ref={sliderRef}>
      {slides.map((slide, index) => {
        let slideToShow = '';

        switch (index){
          // case 0:
          // slideToShow = (
          //   <>
          //     <div className="carousel_video" style={{ backgroundColor: "#000" }}>
          //       <div className="video_content col-md-12" style={{ position: "relative", zIndex: "10" }}>
          //       <video className='video_section1' src={slide.video} muted loop autoPlay/>
          //         {/* <VideoPlayerSlide videoId={slide.link} /> */}
          //       </div>
          //     </div>
          //   </>
          // );
          // break;
          // case 0:
          //   slideToShow = (<>
          //     <div className="video_content col-md-6" style={{position: 'relative',height: '950px',width:'100vw'}}>
          //       <video className='video_section1' src={slide.video} style={{height: '100%',objectFit: 'cover'}} muted loop autoPlay/>
          //     </div>
          //     <div className = {`text_content col-md-6 ${slide.position}`} style={{ left: '5rem' }}>
          //       <h1 className='text-white'>{slide.title}</h1>
          //       <p className='lineHeight-4'>{slide.description}</p>
          //        <div className='d-flex flex-row gap-8 mt-4'>
          //       {slide.button === 'yes' && <span><Link target="_blank" to={slide.link} className='btn btn-primary btn-show mt-3'>Learn More</Link></span>}
          //       </div>
          //     </div>
          //   </>);
          //   break;
            // case 2:
            //   slideToShow = (<>
            //   <div className='carousel_video' style={{backgroundColor: '#000'}}>
            //   <div className="video_content col-md-6" style={{position: 'relative', right: '-25%'}}>
            //     <video className='video_section1' src={Fraud} muted loop autoPlay/>
            //   </div>
            //   <div className = {`text_content col-md-6 ${slide.position}`} style={{ left: '5rem' }}>
            //     <h1 className='text-white'>{slide.title}</h1>
            //     <p className='lineHeight-4'>{slide.description}</p>
            //     <div className='d-flex flex-row gap-8 mt-4'>
            //       {slide.button === 'yes' && <span style={{top: '23rem', right: '3rem'}} className='btn btn-secondary btn-show1 mt-3' onClick={handleOpenModal}>More Options</span>}
            //     </div>
            //   </div>
            // </div>
            // </>);
            // break;
            case 1:
              slideToShow = (<>
                <div className="video_content col-md-6" style={{position: 'relative',height: '950px',width:'100vw'}}>
                  <video className='video_section1' src={slide.video} style={{height: '100%',objectFit: 'cover'}} muted loop autoPlay/>
                </div>
                <div className = {`text_content col-md-6 ${slide.position}`} style={{ left: '5rem' }}>
                  <h1 className='text-white'>{slide.title}</h1>
                  <p className='lineHeight-4'>{slide.description}</p>
                   <div className='d-flex flex-row gap-8 mt-4'>
                  {slide.button === 'yes' && <span><Link target="_blank" to={slide.link} className='btn btn-primary btn-show mt-3'>Learn More</Link></span>}
                  </div>
                </div>
              </>);
              break;
            // case 5:
            //   slideToShow = (<>
            //     <div className="video_content col-md-6" style={{position: 'relative',height: '950px',width:'100vw'}}>
            //       <video className='video_section1' src={slide.video} style={{height: '100%',objectFit: 'cover'}} muted loop autoPlay/>
            //     </div>
            //     <div className = {`text_content col-md-6 ${slide.position}`} style={{ left: '5rem' }}>
            //       <h1 className='text-white'>{slide.title}</h1>
            //       <p className='lineHeight-4'>{slide.description}</p>
            //        <div className='d-flex flex-row gap-8 mt-4'>
            //       {slide.button === 'yes' && <span><Link target="_blank" to={slide.link} className='btn btn-primary btn-show mt-3'>Learn More</Link></span>}
            //       </div>
            //     </div>
            //   </>);
            //   break;
          // case 10:
          //   slideToShow = (<>
          //     <div className='carousel_video' style={{backgroundColor: '#000'}}>
          //     <div className="video_content col-md-6" style={{position: 'relative'}}>
          //       <video className='video_section1' src={Teaser} muted loop autoPlay/>
          //     </div>
          //     <div className = {`text_content col-md-6 ${slide.position}`} style={{ left: '5rem' }}>
          //       <h1 className='text-white'>{slide.title}</h1>
          //       <p className='lineHeight-4'>{slide.description}</p>
          //       <div className='d-flex flex-row gap-8 mt-4'>
          //         {slide.button === 'yes' && <span style={{top: '23rem', right: '3rem'}} className='btn btn-secondary btn-show1 mt-3' onClick={handleOpenModal}>More Options</span>}
          //       </div>
          //     </div>
          //   </div>
          //   </>);
          //   break;
          // case 7:
          //   slideToShow = (<>
          //   <div className='carousel_video' style={{backgroundColor: '#000'}}>
          //   <div className="video_content col-md-6" style={{position: 'relative'}}>
          //     <video className='video_section' src={Fraud} muted loop autoPlay/>
          //   </div>
          //   <div className = {`text_content col-md-6 ${slide.position}`} style={{ left: '2rem' }}>
          //     <h1 className='text-white'>{slide.title}</h1>
          //     <p style={{lineHeight:'4.5rem'}}>{slide.description}</p>
          //     <div className='d-flex flex-row gap-8 mt-4'>
          //       {slide.button === 'yes' && <span><Link target="_blank" to={slide.link} className='btn btn-primary btn-show mt-3'>Learn More</Link></span>}
          //       {slide.document === 'yes' && <span><Link target="_blank" to={slide.link} className='btn btn-primary btn-show mt-3'>Document</Link></span>}
          //     </div>
          //   </div>
          // </div>
          // </>)
          //   break;
          default:
            slideToShow = (<>
              <div className="img_content row" style={{ backgroundImage: `url(${slide.image})`, height: '950px' }}>
                {slide.overlay === 'yes' &&  <div className="overlay_bg"></div>}
                <div className = {`text_content col-md-6 ${slide.position}`} style={{left: '5rem' }}>
                  <h1 className='text-white'>{slide.title}</h1>
                  <p className='lineHeight-4'>{slide.description}</p>
                  <div className='d-flex flex-row gap-8 mt-4'>
                    {slide.button === 'yes' && <span><Link target="_blank" to={slide.link} className='btn btn-primary btn-show mt-3'>Learn More</Link></span>}
                    {slide.document === 'yes' && <span><Link target="_blank" to={slide.link} className='btn btn-primary btn-show mt-3'>Document</Link></span>}
                  </div>
                </div>
              </div>
            </>)
            break;
        }
        return (
          <div className='content' style={{ position: 'relative' }} key={index}>
              {slideToShow}
          </div>
        )
      })}
    </Slider>
    <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Add Modal"
      >
        <div className="container">
          <h2 className='text-center mb-5 mt-2'>Choose Your Video</h2>
          <div className="row">
            <div className="col-md-6">
              <h4 class='mb-2'>The Full Episode :</h4>
              <VideoPlayer videoId={videoId1}/>
            </div>
            <div className="col-md-6">
              <h4 class='mb-2'>Best Of :</h4>
              <VideoPlayer videoId={videoId2}/>
            </div>
          </div>
        </div>
       
      </Modal>
    </>

  );
};

export default Carousel;
