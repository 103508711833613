import React, { useState, useEffect } from 'react';
import Counter from '../Components/index/counter';
// import Systems from '../Components/index/Systems';
import Systems from '../Components/index/Systems';
import SystemsV2 from '../Components/index/SystemsV2';
import Services from '../Components/index/Services';
import Business from '../Components/index/Business';
import Introduction from '../Components/index/Introduction';
import Compliance from '../Components/index/Compliance';
import Clients from '../Components/index/Clients';
import Media from '../Components/index/Media';
// import Wheel from '../Components/index/Wheel//Wheel';
// import Geospatial from '../Components/index/Geospatial';
import '../style.scss';
import Fade from 'react-reveal/Fade';
import { useLocation, Link } from "react-router-dom";
import Test from '../Components/index/SystemsAnimation';
import SystemsAnimation from '../Components/index/SystemsAnimation';


const Home = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
    setIsMobile(isMobileDevice);
  }, []);
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className='homePage'>
      {isMobile ? (
        <div>
          <Introduction />
          <Compliance />
          {/* <Test/> */}
          <SystemsAnimation />   
          {/* <Systems2 /> */}
          <Business />
          <Services />
          <Clients />
          <Counter />
          <Media />
        </div>
      ) : (
        <Fade className='fade_animation' bottom>
          <Introduction />
          <Compliance />
          {/* <Test/> */}
          <SystemsAnimation />
          {/* <Systems2 /> */}
          <Business />
          <Services />
          <Clients />
          <Counter />
          <Media />
        </Fade>
      )}
    </div>
  );
};

export default Home;
