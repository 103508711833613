import React, { useEffect, useState } from "react";
import "./SystemsAnimation.css";
import SectionArray from "../../ValooresNew/components/Vtech/Sections/sectionsArray";
// import SectionArray from './sectionsArray';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AML from "../../img/services/aml.jpg";
import vfdshome from "../../img/systemshome/vfdshome.jpg";
import vcishome from "../../img/systemshome/vcishome.jpg";
import vtechhome from "../../img/systemshome/vtechhome1.jpg";
import vcmshome from "../../img/systemshome/vcmshome3.jpg";
import vrschome from "../../img/systemshome/vrschome.jpg";
import service2 from "../../img/Service2.jpg";
import service3 from "../../img/Service3.jpg";
import service4 from "../../img/Service4.jpg";
import Vcms from "../../img/vcms_white.png";
import Vrsc from "../../img/VRCS-white.png";
import Vfds from "../../img/VFDS-white.png";
import Vtech from "../../img/Vtech-white.png";
import Vcis from "../../img/VCIS-logo-white.png";
// import back from "../../img/systemshome/systemsbackground.jpg";
import back from "../../img/systemshome/backphoto.jpg";
// import backvideo from "../../img/fraud.mp4";
// import backvideocarousel from "../../img/systemshome/3dcarossel.mp4";
import backvideocarousel from "../../img/systemshome/carousel_3.mp4";
import backvideo from "../../img/systemshome/AIchap3(1)_carousel_bg.mp4";

import Fade from "react-reveal/Fade";
import { displayName } from "react-quill";
const SystemsAnimation = () => {
  const [fadeOut, setFadeOut] = useState(false);
  const [activeSlide, setActiveSlide] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showSystemsAnimation, setShowSystemsAnimation] = useState(true);
  const [showSystems, setShowSystems] = useState(false);
  const [style,setStyle]=useState({});
  const [stylevideo,setStylevideo]=useState({});

    useEffect(() => {
      const handleScroll = () => {
        const element = document.getElementById("systems-animation");
        if (element) {
          const rect = element.getBoundingClientRect();
          const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
          if (isInView) {
            setIsVisible(true);
          }
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);
  
    // useEffect(() => {
    //   if (isVisible) {
    //     setShowSystemsAnimation(true);
    //     const timer = setTimeout(() => {
    //       setShowSystemsAnimation(false);
    //           setShowSystems(true);
    //     }, 10000);
    //     return () => clearTimeout(timer);
    //   }
    // }, [isVisible]);


    useEffect(() => {
      if (isVisible) {
        // Set the style while video is showing
        setStyle({
          position: 'relative',
          opacity:'0'
        });
        setStylevideo({
          position: 'relative',
          opacity:'1'
        });
  
        const videoTimer = setTimeout(() => {
          // After 10 seconds, reset the style
          setStyle({
            position: 'relative',
            opacity:'1'
          });
          setStylevideo({
            position: 'relative',display:'none'
          })
          setShowSystemsAnimation(false);  // Hide the video
        }, 7000);
  
        const systemsTimer = setTimeout(() => {
          setShowSystems(true);  // Show the systems after 5 seconds
        }, 5000);
  
        return () => {
          clearTimeout(videoTimer);
          clearTimeout(systemsTimer);
        };
      }
    }, [isVisible]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    initialSlide: 1,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };
  
  const handleSlideClick = (index) => {
    setActiveSlide(index);
  };
  const goToUrl = (url) => {
    window.open(url, "_blank");
  };
  
  const service = [
    {
      image: vfdshome,
      titleImg: Vfds,
      title: "V.FDS",
      description:
      "Compliance Solutions provide real-time AI-driven monitoring and automated reporting, ensuring financial institutions, fintechs, and crypto exchanges stay ahead—enhancing risk mitigation, regulatory compliance, and fraud detection",
      link: "/VFDS",
    },
    {
      image: vcmshome,
      titleImg: Vcms,
      title: "V.CMS",
      description:
      "Secures digital assets, protecting crypto transactions, detecting illicit transfers, and preventing unauthorized activities in a rapidly evolving financial landscape.",
      link: "/VCMS",
    },
    {
      image: vtechhome,
      titleImg: Vtech,
      title: "V.Tech",
      description:
      "Designed for industries that demand agility and precision, VALOORES transforms data into actionable insights, enabling smarter decisions and sharper strategies to embrace a technology that keeps your business at the forefront of innovation and growth. Where AI and machine learning are seamlessly woven into every level of decision-making...",
      // link: '/banking/riskandcompliance?variable=regulatoryreportingsolution'
      link: "/Vtech/",
    },
    {
      image: vrschome,
      titleImg: Vrsc,
      title: "V.RSC",
      description:
      "Harnesses years of Valooris retail expertise to transform consumer insights. It leverages geospatial data, real-time analytics, and customer clustering to revolutionize marketing, streamline supply chains, and elevate in-store experiences for maximum impact",
      link: "/banking/feature?page=financialservices",
    },
    {
      image: vcishome,
      titleImg: Vcis,
      title: "V.CIS",
      description:
      "System offers a borderless scope that harnesses the vast potential of geospatial data to safeguard against the most pressing threats in the world: terrorism, fraud and corruption, Human smuggling, border control, pipeline security, illicit activities, tourism safety, and airport traffic management.",
      link: "/banking/feature?page=financialservices",
    },
  ];
  
  return (
    <div id='systems-animation' style={{position: 'relative'}}>
      <div className="services__title">
        <h2>
          <span>VALOORES: Systems </span>That Empower Your Every Move!
        </h2>
      </div>
      {showSystemsAnimation && (
        <div style={stylevideo}>
          <div
            className="py-5 industryPage video-background"
            style={{ width: "100vw", height: "63vh" }}
            >
            <video autoPlay loop muted playsInline className="background-video">
              <source src={backvideocarousel} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}

      {showSystems && (
        <div style={style}>
          <div className="py-5 industryPage video-background">
            <video autoPlay loop muted playsInline className="background-video">
              <source src={backvideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <div className="background-overlay"></div> */}
            <div className="container vtech_container">
              <Slider {...settings}>
                {service.map((section, index) => (
                  <div
                  key={index}
                  className={`section-slide ${
                    (windowWidth >= 1025 && index === activeSlide) || (windowWidth < 1025 && index === activeSlide)
                      ? "active"
                      : "deactivate"
                  }`}
                    onClick={() => {
                      handleSlideClick(index);
                      goToUrl(section.link);
                    }}
                    style={{
                      boxShadow:
                      index === activeSlide
                      ? "10px 10px 10px rgba(255, 255, 255, 0.1)"
                      : "0px 2px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: "10px",
                      transition: "box-shadow 0.3s ease-in-out",
                    }}
                    >
                    <div
                      style={{
                        backgroundImage: `url(${section.image})`,
                        height: "15rem",
                        width: "100%",
                        backgroundPosition: "center top 20%",
                        backgroundSize: "cover",
                        borderRadius: "10px 10px 0 0",
                      }}
                    />
                    <div className="p-3">
                      <h5>{section.title}</h5>
                      <h6 style={{ fontSize: "0.9rem" }}>
                        {section.description}
                      </h6>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SystemsAnimation;

// import React, { useEffect, useState } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "./SystemsAnimation.css";
// import back from "../../img/systemshome/backphoto.jpg";
// import vfdshome from "../../img/systemshome/vfdshome.jpg";
// import vcishome from "../../img/systemshome/vcishome.jpg";
// import vtechhome from "../../img/systemshome/vtechhome1.jpg";
// import vcmshome from "../../img/systemshome/vcmshome3.jpg";
// import vrschome from "../../img/systemshome/vrschome.jpg";
// import Vcms from "../../img/vcms_white.png";
// import Vrsc from "../../img/VRCS-white.png";
// import Vfds from "../../img/VFDS-white.png";
// import Vtech from "../../img/Vtech-white.png";
// import Vcis from "../../img/VCIS-logo-white.png";

// const SystemsAnimation = () => {
//   const [slowRotation, setSlowRotation] = useState(false);
//   const [showSlider, setShowSlider] = useState(false);

//   useEffect(() => {
//     const slowTimer = setTimeout(() => {
//       setSlowRotation(true);
//     }, 5000); // Switch to slow rotation after 10s

//     const sliderTimer = setTimeout(() => {
//       setShowSlider(true);
//     }, 40000); // After 40s, switch to slider

//     return () => {
//       clearTimeout(slowTimer);
//       clearTimeout(sliderTimer);
//     };
//   }, []);

//   const service = [
//     { image: vfdshome, titleImg: Vfds, title: "VFDS" },
//     { image: vcmshome, titleImg: Vcms, title: "VCMS" },
//     { image: vtechhome, titleImg: Vtech, title: "Vtech" },
//     { image: vrschome, titleImg: Vrsc, title: "VRSC" },
//     { image: vcishome, titleImg: Vcis, title: "VCIS" },
//   ];

//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     centerMode: true,
//     responsive: [
//       { breakpoint: 1024, settings: { slidesToShow: 2 } },
//       { breakpoint: 768, settings: { slidesToShow: 1 } },
//     ],
//   };

//   return (
//     <div className="py-5 industryPage" style={{ backgroundImage: `url(${back})`, backgroundPosition: "center top 20%" }}>
//       <div className="banner">
//         {!showSlider ? (
//           <div className={`slider ${slowRotation ? "slow" : "fast"}`} style={{ "--quantity": service.length }}>
//             {service.map((section, index) => (
//               <div className="item" style={{ "--position": index + 1 }} key={index}>
//                 <div className="image-container" style={{ backgroundImage: `url(${section.image})` }} />
//                 <div className="title-container">
//                   <h5>{section.title}</h5>
//                 </div>
//               </div>
//             ))}
//           </div>
//         ) : (
//           <Slider {...sliderSettings}>
//             {service.map((section, index) => (
//               <div key={index} className="section-slide">
//                 <div className="image-container" style={{ backgroundImage: `url(${section.image})` }} />
//                 <div className="title-container">
//                   <h5>{section.title}</h5>
//                 </div>
//               </div>
//             ))}
//           </Slider>
//         )}
//       </div>
//     </div>

// import React, { useEffect, useState } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "./SystemsV2.css";

// import vfdshome from "../../img/systemshome/vfdshome.jpg";
// import vcishome from "../../img/systemshome/vcishome.jpg";
// import vtechhome from "../../img/systemshome/vtechhome1.jpg";
// import vcmshome from "../../img/systemshome/vcmshome3.jpg";
// import vrschome from "../../img/systemshome/vrschome.jpg";
// import Vfds from "../../img/VFDS-white.png";
// import Vcms from "../../img/vcms_white.png";
// import Vrsc from "../../img/VRCS-white.png";
// import Vtech from "../../img/Vtech-white.png";
// import Vcis from "../../img/VCIS-logo-white.png";
// import back from "../../img/systemshome/backphoto.jpg";

// const SystemsAnimation = () => {
//   const [activeSlide, setActiveSlide] = useState(1);
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);
//   const [isRotating, setIsRotating] = useState(true);
//   const [sliderSettings, setSliderSettings] = useState({
//     dots: false,
//     infinite: true,
//     speed: 200,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     centerMode: true,
//     centerPadding: "0px",
//     initialSlide: 1,
//     beforeChange: (current, next) => setActiveSlide(next),
//   });

//   useEffect(() => {
//     // Stop rotation after 10 seconds
//     const timer = setTimeout(() => {
//       setIsRotating(false);
//       setSliderSettings((prev) => ({
//         ...prev,
//         infinite: false, // Stop auto-looping
//         speed: 1000, // Slow transition
//         centerPadding: "5%", // Bring elements closer
//       }));
//     }, 10000);

//     return () => clearTimeout(timer);
//   }, []);

//   useEffect(() => {
//     const handleResize = () => setWindowWidth(window.innerWidth);
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const handleSlideClick = (index) => setActiveSlide(index);

//   const service = [
//     { image: vfdshome, titleImg: Vfds, title: "VFDS", link: "/VFDS" },
//     { image: vcmshome, titleImg: Vcms, title: "VCMS", link: "/VCMS" },
//     { image: vtechhome, titleImg: Vtech, title: "Vtech", link: "/Vtech/" },
//     { image: vrschome, titleImg: Vrsc, title: "VRSC", link: "/banking/feature?page=financialservices" },
//     { image: vcishome, titleImg: Vcis, title: "VCIS", link: "/banking/feature?page=financialservices" },
//   ];

//   return (
//     <div>
//       <div className="services__title">
//         <h2><span>VALOORES: Systems </span>That Empower Your Every Move!</h2>
//       </div>
//       <div className="py-5 industryPage" style={{ backgroundImage: `url(${back})`, backgroundPosition: "center top 20%", position: "relative" }}>
//         <div className="background-overlay"></div>
//         <div className="container vtech_container">
//           <Slider {...sliderSettings}>
//             {service.map((section, index) => (
//               <div
//                 key={index}
//                 className={`section-slide ${windowWidth >= 1025 ? (index === activeSlide ? "active" : "deactivate") : ""}`}
//                 onClick={() => handleSlideClick(index)}
//                 style={{
//                   boxShadow: index === activeSlide ? "10px 10px 10px rgba(255, 255, 255, 0.1)" : "0px 2px 10px rgba(0, 0, 0, 0.1)",
//                   borderRadius: "10px",
//                   transition: "box-shadow 0.3s ease-in-out",
//                 }}
//               >
//                 <div
//                   style={{
//                     backgroundImage: `url(${section.image})`,
//                     height: "15rem",
//                     width: "100%",
//                     backgroundPosition: "center top 20%",
//                     backgroundSize: "cover",
//                     borderRadius: "10px 10px 0 0",
//                   }}
//                 />
//                 <div className="p-3">
//                   <h5>{section.title}</h5>
//                 </div>
//               </div>
//             ))}
//           </Slider>
//         </div>
//       </div>
//     </div>

// <div className="banner">
    //   <div className="slider" style={{ "--quantity": "10" }}>
    //     {Array.from({ length: 10 }).map((_, index) => (
    //       <div className="item" style={{ "--position": index + 1 }} key={index}>
    //         <img src={vrschome} style={{ width: "200px" }} alt="VRSC Home" />
    //       </div>
    //     ))}
    //   </div>
    // </div>
    
    // return (
      //   <div>
      //     <div class="banner">
      //       <div class="silder" style={{ "--quantity": "10" }}>
      //         <div class="item" style={{ "--position": "1" }}>
      //           <img src={vrschome} style={{ width: "200px" }} alt="dasd" />
      //         </div>
    //         <div class="item" style={{ "--position": "2" }}>
    //           <img src={vrschome} style={{ width: "200px" }} alt="dasd" />
    //         </div>
    //         <div class="item" style={{ "--position": "3" }}>
    //           <img src={vrschome} style={{ width: "200px" }} alt="dasd" />
    //         </div>
    //         <div class="item" style={{ "--position": "4" }}>
    //           <img src={vrschome} style={{ width: "200px" }} alt="dasd" />
    //         </div>
    //         <div class="item" style={{ "--position": "5" }}>
    //           <img src={vrschome} style={{ width: "200px" }} alt="dasd" />
    //         </div>
    //         <div class="item" style={{ "--position": "6" }}>
    //           <img src={vrschome} style={{ width: "200px" }} alt="dasd" />
    //         </div>
    //         <div class="item" style={{ "--position": "7" }}>
    //           <img src={vrschome} style={{ width: "200px" }} alt="dasd" />
    //         </div>
    //         <div class="item" style={{ "--position": "8" }}>
    //           <img src={vrschome} style={{ width: "200px" }} alt="dasd" />
    //         </div>
    //         <div class="item" style={{ "--position": "9" }}>
    //           <img src={vrschome} style={{ width: "200px" }} alt="dasd" />
    //         </div>
    //         <div class="item" style={{ "--position": "10" }}>
    //           <img src={vrschome} style={{ width: "200px" }} alt="dasd" />
    //         </div>
    //       </div>
    //     </div>
    //   </div>