// import Financial from '../img/carousel/financial.jpg';
import Financial from '../img/carousel/Financial_carousel.jpg';
import Retail from '../img/carousel/retail_carousel.jpg';
// import Geospatial from '../img/carousel/geospatial.jpg';
// import Geo from '../img/carousel/geo.jpg';
import AI from '../img/carousel/ai.jpg';
import AI999 from '../img/carousel/AI_carousel.jpg';
import Government from '../img/carousel/goverment.jpg';
import Bigdata from '../img/carousel/bigdata.jpg';
import Knowledge from '../img/carousel/knowledge.jpg';
import Slide0 from '../img/carousel/slide0.jpg';
import Slide001 from '../img/carousel/slide001.jpg';
// import Slide01 from '../img/carousel/fam.jpg';
// import Slide1 from '../img/carousel/slide1.jpg';
// import Slide6 from '../img/carousel/slide6.jpg';
import Multimedia from '../img/carousel/Multimedia_carousel.jpg';
import SlideLogo from '../img/carousel/slideLogo.jpg';
import Slide7 from '../img/carousel/slide7.jpg';
import Slide10 from '../img/carousel/slide10.jpg';
import Fatf0 from '../img/Fatf/carousell.jpg';
import Christmas from '../img/Fatf/Christmas.png';
// import eye from '../img/vtechtest.png';
import eye from '../img/vtechcarouselbg.png';
import aiVideo from '../../src/img/AI.mp4'
import BigDataVideo from '../../src/img/bigData.mp4'
import BigData from '../img/carousel/Carousel_BigData.jpg'
import CarouselVideo from '../img/carousel/Fatf2_carousel.mp4'
import v2121 from '../img/carousel/v2121.jpg'
import v20 from '../img/carousel/v20_dark_blue.jpg'
import ValooresFam from '../img/carousel/Valoores_Fam.jpg';
import Albert from '../img/carousel/Abyad.jpg';

const slides = [
  // {
  //   image: eye,
  //   title: '',
  //   description: "",
  //   position: 'slideAiPos',
  //   link: '',
  //   button: 'no',
  //   document: 'no',
  //   overlay: 'no'
  // },
  {
    image: AI999,
    video:aiVideo,
    title: 'Artificial Intelligence',
    description: "AI is the cornerstone of our software solutions at Valoores. It's essential across sectors like retail, finance, and government, addressing challenges, multitasking, and meeting consumer expectations in a rapidly evolving landscape.",
    position: 'slideAiPos',
    link: '/ai',
    button: 'yes',
    document: 'no',
    overlay: 'yes'
  },
  // {
  //   image: Christmas,
  //   title: '',
  //   description: '',
  //   position: '',
  //   link: 'kAe_nS0NGwo',
  //   button: 'no',
  //   document: 'no',
  //   overlay: 'no'
  // },
  {
    image: Fatf0,
    video:CarouselVideo,
    title: '',
    description: (
      <>
        <h1 className='text-white'><strong>FATF QUANTUM LEAP</strong></h1>
        <h2 className='text-white'>Transforming Compliance Standards The VALOORES Blueprint Crafting Compliance & Investigation Excellence.</h2>
      </>
    ),
    position: 'slidePos',
    link: '/Fatf',
    button: 'yes',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: Albert,
    title: '',
    description: (
      <>
        We can't solve problems by using the same kind of thinking we used when we created them. The whole of science is nothing more than a refinement of everyday thinking. <br />
        <span style={{ fontStyle: 'italic' }}>~ Albert Einstein ~</span>
      </>
    ),
    position: 'slidePos',
    link: '/banking/inAnalytics',
    button: 'no',
    document: 'no',
    overlay: 'no'
  },
  {
    image: Retail,
    title: 'Retail and Supply Chain',
    description: 'Valoores uses supply chain technologies and advanced retail services for responding to market needs.',
    position: 'slidePos',
    link: '/Geospatial/retail-data',
    button: 'yes',
    document: 'no',
    overlay: 'yes'
  },
  
  
  {
    image: Knowledge,
    title: "in'VCIS Technology & Data Management",
    description: "Poor data quality can cost businesses millions, but Valoores can help mitigate risks and reduce overall costs.",
    position: 'slidePos',
    link: 'https://indatacrowd.ai/',
    button: 'yes',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: BigData,
    video:'',
    title: (
      <>
        Big Data Isn't About<br /> Bits, But Talent.
      </>
    ),
    description: (
      <>
        A wealth of information<br /> creates a poverty of attention.<br /> With big data, comes big<br /> responsibility to derive insight,<br/> make decisions and drive value.
      </>
    ),
    position: 'slideBigPos',
    link: '/ai',
    button: 'yes',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: Government,
    title: 'Government',
    description: 'Valoores solutions facilitate the use of information and communication technologies to provide and improve public-sector services',
    position: 'slidePos',
    link: '/solutions/gov',
    button: 'yes',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: Financial,
    title: 'Financial Services',
    description: 'Valoores Delivers intelligent financial solutions and productivity-enhancing case management tools for financial institutions.',
    position: 'slidePos',
    link: '/banking',
    button: 'yes',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: Multimedia,
    title: (
      <>
        <div style={{ color: '#fff' }}>
          Multichannel Communication & Multimedia
        </div>
      </>
    ),
    description: (
      <>
        <div style={{ color: '#fff' }}>
          We offer a comprehensive suite of services that enable organizations to connect, captivate, and collaborate effectively in today's dynamic digital landscape.
        </div>
      </>
    ),
    position: 'slideMultiPos',
    link: '/Multimedia',
    button: 'yes',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: v20,
    title: '',
    description: 
    (
      <>
        <div className='slide10Font'>
          VALOORES announces the
          Next Generation v20 Ecosystem<br />
          That marches at the same drum
          with the latest Digital Transformation guidelines
        </div>
      </>
    ),
    position: 'slideEcoPos',
    // link: 'https://valoores.com/v20.php',
    link: '/V21',
    button: 'yes',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: Slide7,
    title: (
      <>
        <div style={{ fontSize: '2.5rem', lineHeight: '2.8rem', fontWeight: "500" }}>
          You have the VALUE,<br />
          we bring you the<br /> <span style={{}}>Added VALUE</span><br /><br />
          it's VALOORES!
        </div>
      </>
    ),
    description: '',
    position: 'slidePos',
    link: 'https://indatacrowd.ai/',
    button: 'no',
    document: 'no',
    overlay: 'yes'
  },
  // {
  //   image: SlideLogo,  
  //   title: '',
  //   description: '',
  //   position: 'slideFirst',
  //   link: '/banking/inAnalytics',
  //   button: 'yes',
  //   document: 'no',
  //   overlay: 'yes'
  // },
  // {
  //   image: Slide001,  
  //   title: '',
  //   description: (<>
  //     The 2023 Award winner Nehmé Y. Taouk (CEO & Founder, VALOORES, Lebanon) <br /> <br /> Advocacy and public policy engagement. Fostering social cohesion and inter-group dialogue and relationship-building in the workplace, marketplace and local community. 
  //   </>),
  //   position: 'slideTeam',
  //   link: '/banking/inAnalytics',
  //   button: 'no',
  //   document: 'no',
  //   overlay: 'yes'
  // },
  
  {
    image: ValooresFam,
    title: '',
    description: (
      <>
          <h2 className='mb-3 text-white' style={{fontSize: '1.5rem'}}>The company is proud to have received <br /> <span style={{fontStyle: 'italic'}}>The Global Business and Intercultural<br /> peace award - 2023</span></h2>
          <h4 className='text-white'>we attribute our success to the hard work and dedication of our exceptional team, with their belonging and accountability to deliver the<br /> best always on time.</h4>
      </>
      ),
    position: 'slidePos',
    link: '/banking/inAnalytics',
    button: 'no',
    document: 'no',
    overlay: 'yes'
  },
];





  export default slides;