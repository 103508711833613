import React ,{useState} from 'react';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';

// const VideoPlayer = ({ videoId }) => {
//   const opts = {
//     height: '350',
//     width: '100%',
//     playerVars: {
//       autoplay: 0,
//       modestbranding: 1,
//       mute: 1,
//       rel: 0,
//     },
//   };

//   return videoId ? <YouTube videoId={videoId} opts={opts} /> : <p>No video available</p>;
// };
const VideoPlayer = ({ videoId }) => {
  const [videoEnded, setVideoEnded] = useState(false); 
  const [autoplay, setAutoPlay] = useState(0); 

  const opts = {
    height: "350",
    width: "100%",
    playerVars: {
      autoplay: autoplay, 
      mute: 1,
      modestbranding: 1,
      rel: 0,
    },
  };

  const handleEnd = () => {
    setVideoEnded(true); 
    setAutoPlay(0); 
  };

  const handlePlayAgain = () => {
    setVideoEnded(false);
    setAutoPlay(1); 
  };

  return (
    <div style={{ position: "relative" }}>
      {!videoEnded ? (
        <YouTube
          videoId={videoId}
          opts={opts}
          onEnd={handleEnd} 
        />
      ) : (
        <div style={{ position: "relative", width: "100%" }} onClick={handlePlayAgain}>
          <img
            src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`} 
            alt="Video Thumbnail"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      )}
    </div>
  );
};

const servicesdata = [
  {
    title: 'V-FDS',
    video: 'CSpcauTkGfo',
    desc: 'V-FDS Compliance Solutions provide real-time AI-driven monitoring and automated reporting, ensuring financial institutions, fintechs, and crypto exchanges stay ahead—enhancing risk mitigation, regulatory compliance, and fraud detection'
  },
  {
    title: 'V-CMS',
    video: 'fiHFL-x1ASM',
    desc:'V-CMS secures digital assets, protecting crypto transactions, detecting illicit transfers, and preventing unauthorized activities in a rapidly evolving financial landscape.'
  },
  {
    title: 'V-TECH',
    video: 'gREwuT4-9_k',
    desc:"V-TECH Designed for industries that demand agility and precision, VALOORES transforms data into actionable insights, enabling smarter decisions and sharper strategies to embrace a technology that keeps your business at the forefront of innovation and growth. Where AI and machine learning are seamlessly woven into every level of decision-making. Our systems drive clarity from complexity, supporting sectors from retail and banking to government and law enforcement with real-time intelligence.",
  },
  {
    title: 'V-RSC',
    video: 'gFZrIEIw2Ng',
    desc:'V-RCS harnesses years of Valooris retail expertise to transform consumer insights. It leverages geospatial data, real-time analytics, and customer clustering to revolutionize marketing, streamline supply chains, and elevate in-store experiences for maximum impact'
  },
  {
    title: 'V-CIS',
    video: 'tUL_f-Cgjy4',
    desc:'V-CIS system offers a borderless scope that harnesses the vast potential of geospatial data to safeguard against the most pressing threats in the world: terrorism, fraud and corruption, Human smuggling, border control, pipeline security, illicit activities, tourism safety, and airport traffic management.'
  },
];

const Introduction = ({ title }) => {
  const service = servicesdata.find((item) => item.title === title);

//   if (!service) {
//     return <p>Service not found</p>;
//   }

  return (
    <div className="container mt-5 intro_container">
      <div className="row align-items-center">
        <div className="col-md-6">
          <div className="video_player-container">
            <VideoPlayer videoId={service.video} />
          </div>
        </div>
        <div className="col-md-6">
          <div className="content__title1">
            <h2 style={{textAlign:'center'}}>
              About {service.title}
            </h2>
            <p style={{fontSize:'17pt',fontWeight:'500'}}>
                {service.desc}
              {/* Enterprise Business Solutions in Financial Services, Cyber Crimes, BI, Analytics, in Advanced Certified Anti-Hacking and State Security Clouds Model. VALOORES is one of the leading providers of Enterprise Business Solutions and BI Vertical Decision Making. We are a World Class Company implementing products and services to Tier1 Corp. Our resources pool is spread over North America (US and Canada), Europe (Eastern & Western), Africa, and the Middle East. VALOORES, established in 1989, operates in 17 countries. */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
