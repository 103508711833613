import React, { useState} from 'react';
import Fade from 'react-reveal/Fade';
import { Link,  useLocation  } from 'react-router-dom';
import { CiShare1 } from 'react-icons/ci';
import { BiChevronsRight } from 'react-icons/bi';
import InDebtCollection from './content/InDebtCollection';

const Content = (props) => {
  let [activeItem, setActiveItem] = useState('Key Benefits');
  let [activeItemA, setActiveItemA] = useState('Transaction');
  let [activeItemI, setActiveItemI] = useState('Transaction Monitoring');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const receivedVariable = searchParams.get('variable');
  let content, menuVariable, activeMenu, topMenu, overviewSec;

  const handleItemClick = (index) => {
    setActiveItem(index);
    setActiveItemA(index);
    setActiveItemI(index);
  };

  


  // const InDebtCollection = ['Overview', 'Strengthen Your Debt Recovery Strategies', 'Benefits', 'Features'];
  const AssetManage = ['Liquidity risk', 'Interest rate risk', 'Capital risk', 'Market risk (investments and derivatives)', 'Funds transfer pricing (FTP)', 'Stress testing'];
  const AmlMenu = ['Transaction', 'Reporting', 'Exploration', 'Processing', 'Management', 'Risk Based Approach'];
  const FraudMenu = ['Transaction Monitoring', 'Real-time scoring', 'Case Management', 'Single Fraud Platform', 'Case Studies', 'Customer Experience'];

  switch(receivedVariable) {
    case 'DebtCollection':
    // overviewSec = 'yes'
    
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
               
              ))}
          </>
        );
      break;

      case 'BalanceSheet':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx} dangerouslySetInnerHTML={{__html:itemList}}></li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;
      case 'BalanceSheetPlanning':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx} dangerouslySetInnerHTML={{__html:itemList}}></li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;

      case 'IncomeStatement':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;
      case 'InCashFlow':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;

      case 'InFunds':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;

    case 'InRevenue':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;

    case 'InWealthManagement':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;
    case 'InRatios':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;
    case 'InBranch':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;
  
    case 'InAssetManagement':
    menuVariable = AssetManage
    activeMenu = activeItem
    topMenu = 'yes'
    overviewSec = 'yes'
    switch (activeItem) {
      case 'Liquidity risk':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
      break;
      case 'Key Features':
          content = (
          <>
            {props.RiskArray.map((item, index) => (
              <Fade >
                <div key={index} className='m-0 mb-4 px-3'>
                    <ul className='m-0 p-0'>
                    {item.features.map((feature, innerIndex) => (
                        <li key={innerIndex}>
                        <h5>{feature.title}</h5>
                        <ul>
                            {feature.list.map((subItem, subIndex) => (
                            <li key={subIndex}>
                                {subItem.subTitle !== '' ? (
                                <>
                                    <h6>{subItem.subTitle}</h6>
                                    <ul>
                                    {subItem.subList.map((subsubItem, subsubIndex) => (
                                        <li key={subsubIndex}>{subsubItem}</li>
                                    ))}
                                    </ul>
                                </>
                                ) : (
                                <span>
                                    {subItem.subList.map((subsubItem, subsubIndex) => (
                                      <li key={subsubIndex}>{subsubItem}</li>
                                    ))}
                                </span>
                                )}
                            </li>
                            ))}
                        </ul>
                        </li>
                    ))}
                    </ul>
                </div>
              </Fade>
            ))}
          </>
          );
      break;
        
      case 'Case Study':
          content = (
          <>
              {props.RiskArray.map((item, index) => (
                <Fade >
                  <div key={index} className='m-0 mb-4 px-3'>
                  {item.caseStudy.map((caseS, innerIndex) => (
                      <div key={innerIndex}>
                      <p>{caseS.introduction}</p>
                      {caseS.title && <p className='fw-bold'>{caseS.title}</p>}
                      
                          <>
                          {caseS.heading.title && <p>{caseS.heading.title}</p>}
                          {Array.isArray(caseS.heading.titleList) && (
                              <ul>
                              {caseS.heading.titleList.map((title, titleIndex) => (
                                  <span key={titleIndex}>
                                      {Array.isArray(title.links) && (
                                          <span>
                                              {title.mainTitle.map((subMain, subtitleIndex) => (
                                                  <li key={subtitleIndex}>{subMain}</li>
                                              ))}
                                          </span>
                                      )}
                                      <ul>

                                      
                                  {typeof title === 'object' && title.name && (
                                      <li>{title.name}</li>
                                  )}
                                  {Array.isArray(title.links) && (
                                      <ul>
                                      {title.links.map((subtitle, subtitleIndex) => (
                                          <span key={subtitleIndex}>
                                          <li>{subtitle}</li>
                                          </span>
                                      ))}
                                      </ul>
                                  )}
                                  </ul>
                                  </span>
                              ))}
                              </ul>
                          )}
                          </>
                      {caseS.conclusion && <p>{caseS.conclusion}</p>}
                      </div>
                  ))}
                  </div>
                </Fade>
              ))}
          </>
      );
      break;

        

      case 'Solution Preview':
          content = ( 
              <>
              {props.RiskArray.map((item, index) => (
                  <Fade >
                    <img src={item.solution} className='mb-5 w-100' alt=""/>
                  </Fade>
              ))}
              </>
          )
        break;
      default:
        console.log("asdasdjkashdkajsd",receivedVariable);
        content=(
          <h1>Default</h1>
        )
        break;
    }
    break;
          default:
            content=(
              <h1>Default</h1>
            )
        break;
      }


    
  return (
    <>
      {content}
    </>
  );
};

export default Content;
