const regulatroyArray = [
    {
      title: "in'Regulatory",
      overview: (
        <>
         VALOORES' in’Regulatory solution equips multi-jurisdiction institutions
        with a Business Process Management vehicle, a Data Governance and
        Analytics platform, a report designer to adapt to regulations as they
        metamorphose and a universal dashboard engine to monitor activities.
        In’Regulatory services all customer needs, unlocks commercial value,
        and supports good risk governance.
        </>
      ),
      benefits: [
        "Master Data Management for high quality and data integrity",
        "The mobile way and the use of effective technology efficiently",
        " KYC, Know Your Customer as much as you Know Your Team",
        "Keep the radar screen for reducing sanctions, taxes, and data defense",
        "The multi-channels: better treatment of both retail and wholesale customer",
        "The risk to maintain on off-shoring and cyber security attack",
        "Designed to be fast and cost effective at delivering core reporting features",
      ],
      conclusion: (
        <>
          In conclusion, IFRS 9 with its forward-looking impairment model is expected to further augment the efficiency of the banking system. It can be concluded that this impairment model has served its main purpose if another financial crisis is averted and thereby the interests of shareholders and general public are safeguarded. Only time can prove the effectiveness of the revised set of guidelines.
          <br /><br />
          Although IFRS 9 is an accounting standard, it is expected to play its part in strengthening the financial institution's credit risk management system in particular and bringing about a sound banking system at large.
        </>
      ),
      features: [
        {
          title: "",
          list: [
            {
              subTitle: "",
              subList: [
                "Provides a universal dashboard and analytics engine to address Forward Looking Forecasts, Scenario Management and Stress testing.",
                "Secures Ad Hoc / Real time scheduled executions across scattered and heterogeneous data sources and platforms.",
                "Configurable interface to level set thresholds and accuracy tolerance, with a supporting audit trail framework, to dynamically design lay outs and layers, then pull out KPIs, KRIs, and security clearances.",
                "Transforms data, including internal capital, risk, valuation, and other spectra into dynamic actionable information.",
              ]
            }
          ]
        },
      ],
      caseStudy: [
        {
          introduction: "",
          title: "",
          heading: {
            title: "",
            titleList: [
                {
                
                    mainTitle: [
                        "The Valoores in'Via Reporting engine goes beyond just delivering reporting with dynamic rule-based computations. It transforms data,including internal capital, risk, valuation, and other spectra into dynamic actionable information, to sustain end-to-end financial regulations reporting across jurisdictions, port findings to dashboards, and automatically compile information outputs the Business and Regulators dictate."
                    ],
                    name: "",
                    links: [""]
                }
            ]
          },
          conclusion: ''
        },
        {
          introduction: "",
          title: "",
          heading: {
            title: "Business Process Management (BPM)",
                titleList: [
                    {
                    
                        mainTitle: [
                            "Business Process Management (BPM) is at the heart of the design, as it secures Ad Hoc / real-time scheduled executions across heterogeneous datasources and platforms, to meet the regulatory requirements in all trading markets around the world. It's tailored to transform the perception of financial regulations reporting from an administrative headache to a valuable asset to drive the business, while securing Risk, Compliance, and Profitability. VFS BPM entails triggering and scheduled events to manage and improve processes, then address exceptions."
                    ],
                        name: "",
                        links: [],
                    },
                ],
            },
            conclusion: ""
        },
        {
          introduction: "",
          title: "",
          heading: {
            title: "In’Regulatory Solution",
            titleList: '',
          },
          conclusion: (
            <>
              In’Regulatory Solution, part of VALOORES' in'Risk Compliance solution set, industrializes the End To End life cycle through our rule-based engine and in'Via Reporting engine, allowing companies to defend their numbers against regulators while meeting their regulatory requirements. In’Regulatory provides a sustainable and robust data infrastructure as well as the ability to aggregate and consolidate data, standardizing enterprise-wide data held across financial institutions. 
            </>
          )
        },
        {
          introduction: "",
          title: "Impairment",
          heading: {
            title: "The Valoores in'Via Reporting engine",
            titleList: [""]
          },
          conclusion: (
            <>
              The Valoores in'Via Reporting engine goes beyond just delivering reporting with dynamic rule-based computations. It transforms data, including internal capital, risk, valuation, and other spectra into dynamic actionable information, to sustain end-to-end financial regulations reporting across jurisdictions, port findings to dashboards, and automatically compile information outputs the Business and Regulators dictate
            </>
          )
        },
        {
          introduction: "",
          title: "",
          heading: {
            title: "In’Regulatory",
            titleList: ''
          },
          conclusion: (
            <>
              In’Regulatory is a holistic, multientity, multi-channel solution made accessible to the whole organization, based off well defined profiles and user rights. Universal Dashboards are exposed to authorized personnel to address exceptions and monitor activities. A Management Reporting Framework is streamlined with the financial regulations reporting pool, to complement the potential with decision-making/subject-matter vantage points, whether with local or consolidated information levels at any point in time.
            </>
          )
        },
        {
          introduction: "",
          title: "",
          heading: {
            title: "In’Regulatory",
            titleList: ''
          },
          conclusion: (
            <>
              In’Regulatory encompasses End-toEnd information processing while perpetually gauging accuracy and execution timelines to institute confidence and weigh in against a "work by exception" paradigm. Its interface is configurable to level set thresholds and accuracy tolerance, with a supporting audit trail framework to dynamically design lay outs and layers, then pull out KPIs, KRIs, and security clearances.
            </>
          )
        },
        {
          introduction: "",
          title: "",
          heading: {
            title: "In’Regulatory",
            titleList: ''
          },
          conclusion: (
            <>
              Analytics, Stress Testing, Forecasting, Trending, Variance, Ratios, Confidence Intervals and other techniques, accentuate the financial regulations reporting Solution to institute what-if simulations and refine strategies, with a validation cycle backbone, going forward.
            </>
          )
        },
      ],
    }
  ];
  
  export default regulatroyArray;
  