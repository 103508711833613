import React, { useEffect, useRef } from "react";
import telecom1a from "../../img/telecom/telecom1a.png";
import telecom1b from "../../img/telecom/telecom1b.png";
import telecom1c from "../../img/telecom/telecom1c.png";
import telecom2 from "../../img/telecom/telecom2.png";
import telecom3 from "../../img/telecom/telecom3.jpg";
import telecom4 from "../../img/telecom/telecom4.jpg";
import telecom5 from "../../img/telecom/telecom5.jpg";
import telecom6 from "../../img/telecom/telecom6.jpg";
import telecom7 from "../../img/telecom/telecom7.jpg";
import telecom8 from "../../img/telecom/telecom8.jpg";
import telecom9 from "../../img/telecom/telecom9.jpg";

import teleBanner6 from "../../img/telecom/teleBanner6.jpg";
import teleBanner from "../../img/telecom/telecomunication.jpg";
import Banner from "../../ValooresNew/components/General/Banner/Banner";
import "animate.css";
import "./Telecommunication.css";
const TelecomBanner = () => {
  const openPdf = (url, title) => {
    window.open(url, "_blank");
  };
  const imagesRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
          }
        });
      },
      { threshold: 0.5 }
    );

    imagesRef.current.forEach((img) => {
      if (img) observer.observe(img);
    });

    return () => observer.disconnect();
  }, []);

  const data = [
    {
      title: "We have the technology to connect the world",
      desc: "With VCIS you can space cross your limit.",
      img: { teleBanner6 },
      overlay: "on",
    },
  ];

  return (
    <>
      <div
        className="banner_info"
        style={{ backgroundImage: `url(${teleBanner6})` }}
      >
        {data[0].overlay !== "off" && <div className="overlay"></div>}
        <div className={`text_content-banner text-white`}>
          {/* <Fade left> */}
          <div className="animate__animated animate__fadeInLeft">
            <h1 className="font--poppins text-white">{data[0].title}</h1>
            <h4 className="font--poppins text-white" style={{ fontSize: `1rem` }}>
              {data[0].desc}
            </h4>
            {/* </Fade> */}
          </div>
        </div>
      </div>
      {/* <div className="banner telecom__banner">
                <div className="telecom__banner-text animate__animated animate__fadeInUp">
                    <h1>
                        "We have the technology to connect the world,<br /> 
                        With VCIS you can space cross your limit."
                    </h1>
                    <a 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        onClick={() => openPdf("./docs/VCIS-WhitePaper-TransformingDataintoInformation 2023_03_28 01_54", "inVCIS; Transforming Data into Information")} 
                        className="btn slider_button animate__animated animate__fadeInUp animate__delay-1s"
                    >
                        <i className="fa fa-download" aria-hidden="true"></i> Download White Paper
                    </a>
                </div>
            </div> */}

      <div className="telecom-container">
        <div className="telecom__content">
          <h1 style={{fontSize:'2rem',color:'#001d6e',margin:'2rem'}}>2G-5G Network Evolution : </h1>
        </div>
        <div className="telecom__content-img">
          <div className="telecom__content-img_upper">
            <div ref={(el) => (imagesRef.current[0] = el)}>
              <img src={telecom1a} alt="telecom" className="fade-image" style={{width:'30vw'}}/>
            </div>
            <div ref={(el) => (imagesRef.current[1] = el)}>
              <img src={telecom1b} alt="telecom" className="fade-image" style={{width:'30vw'}} />
            </div>
            <div ref={(el) => (imagesRef.current[2] = el)}>
              <img src={telecom1c} alt="telecom" className="fade-image" style={{width:'30vw'}}/>
            </div>
          </div>
          <div ref={(el) => (imagesRef.current[3] = el)}>
            <img src={telecom2} alt="telecom" className="fade-image" style={{width:'80vw'}}/>
          </div>
        </div>

        <div className="telecom__content my-5">
          <h1>
            Location Data Sources and
            <br /> Types of Location Data
          </h1>
          <div className="row flex-col-reverse">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <span className="fw-bold">
                      Global Positioning System (GPS)
                    </span>{" "}
                    A GPS provides latitude-longitude coordinates...
                    <br />
                    <span className="fw-bold">
                      Software Development Kit (SDK)
                    </span>{" "}
                    SDK are codes that app developers put into their
                    applications.
                    <br />
                    <span className="fw-bold">Bidstream</span> Bidstream data is
                    data collected
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    from the ad servers when ads are served on mobile apps and
                    websites.
                    <br />
                    <span className="fw-bold">Beacons</span> Beacons are
                    hardware transmitters that can sense other devices when they
                    come into close proximity.
                    <br />
                    <span className="fw-bold">Wi-Fi</span> Wi-Fi enables devices
                    to emit probes to look for access points (routers).
                    <br />
                    <span className="fw-bold">Point of Sales (POS)</span> POS
                    data is data that stems from consumer transactions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <img src={telecom3} alt="telecom" className="w-100 h-100" />
            </div>
          </div>
        </div>
      </div>

      <div className="telecom__paralax"></div>

      <div className="container">
        <div className="telecom__content my-5">
          <h1>
            Metadata & Telecom
            <br /> Combination With VCIS
          </h1>
          <div className="row">
            <div className="col-md-6 mb-3">
              <img src={telecom4} className="w-100 h-100" alt="telecom" />
            </div>
            <div className="col-md-6">
              <p>
                VCIS Combine Telecom Data with Meta Data in a way where the law
                enforcement can relies on to access new information insights,
                answers the investigator questions that previously remain
                unanswered, streamlines and saves time .If you’re looking for
                another way to increase security and cut costs, connecting
                uncountable data and global information to create a better
                world, in’Datacrowd the key.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="telecom__paralax"></div>
      <div className="container">
        <div className="telecom__content my-5">
          <h1>VCIS With a RAN Network</h1>
          <div className="row my-5">
            <div className="col-md-6 mb-3">
              <img src={telecom6} className="w-100 h-100" alt="telecom" />
            </div>
            <div className="col-md-6">
              <p>
                A RAN provides access to and coordinates the management of
                resources across radio sites...
              </p>
            </div>
          </div>

          <h1>Open RAN by The Numbers</h1>
          <div className="row my-5 mb-3">
            <img src={telecom8} alt="telecom" className="w-100 h-100" />
          </div>

          <h1>VCIS Networks in Telecom</h1>
          <div className="row my-5">
            <div className="col-md-6 mb-3">
              <img src={telecom9} className="w-100 h-100" alt="telecom" />
            </div>
            <div className="col-md-6">
              <p>
                The simplest form of Telecommunications takes place between two
                stations...
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TelecomBanner;
