import React from 'react';
import { Link } from 'react-router-dom';
import Financial from '../../img/carousel/financial.jpg';
import Financial1 from '../../img/financialsolution.jpg';
// import Retail from '../../img/carousel/retail.jpg';
import Retail from '../../img/carousel/retail_carousel.jpg';
import Governement from '../../img/carousel/goverment.jpg';
import Healthcare from '../../img/carousel/healthcare.jpg';
import Telecommunication from '../../img/telecomsolution.jpg';
// import Geospatial from '../../img/carousel/geospatial.jpg';
import Geospatial from '../../img/carousel44.jpg';
// import AI from '../../img/carousel/ai.jpg';
import AI from '../../img/aisolution.jpg';
// import Communication from '../../img/carousel/communication.jpg';
import Communication from '../../img/carousel/communication.jpg';
// import Consulting from '../../img/carousel/consulting.jpg';
import Consulting from '../../img/consultingsolution.jpg';

const Business = () => {
  const business = [
    {
      image: Financial1,
      title: 'Financial Services',
      description: 'Valoores Delivers intelligent financial solutions and productivity-enhancing case management tools for financial institutions... Read More →',
      link: '/banking'
    },
    {
      image: AI,
      title: 'Artificial Intelligence and Big Data',
      description: 'Our Solutions connect the Power of AI to drive Intelligent Automation, Predictive Analytics, and Decision-Making, revolutionizing industries and empowering businesses to achieve efficiency and innovation...  Read More →',
      link: '/ai'
    },
    {
      image: Governement,
      title: 'Government',
      description: 'Valoores solutions facilitates the use of information and communication technologies to provide and improve public-sector services... Read More →',
      link: '/solutions/gov'
      // link: '/solutions/gov'
    },
    {
      image: Telecommunication,
      title: 'Telecommunication',
      description: 'Our approach leverages communication channels to optimize performance, enhance collaboration, and deliver user experiences for governmental and non-governmental needs... Read More →',
      link: '/Telecommunication'
    },
    {
      image: Geospatial,
      title: 'Geospatial Analysis',
      description: 'We have gone beyond basic location data, which simply tells us where things are... Read More →',
      link: '/Geospatial'
    },
    {
      image: Retail,
      title: 'Retail and Supply Chain',
      description: 'Valoores uses supply chain technologies and advanced retail services for responding to market needs... Read More →',
      link: '/Geospatial/retail-data'
    },
    {
      image: Healthcare,
      title: 'Healthcare',
      description: 'VALOORES in\'Healthcare Harnessing big data to derive insights for improving healthcare delivery and reducing waste... Read More →',
      link: '/solutions/healthcare'
    },
    {
      image: Communication,
      title: 'Communication and Multimedia',
      description: 'We offer a comprehensive suite of services that enable organizations to connect, captivate, and collaborate effectively in today\'s dynamic digital landscape...  Read More →',
      link: '/Multimedia'
    },
    {
      image: Consulting,
      title: 'Consulting Services',
      description: 'Our Consulting Services offer strategic guidance and tailored insights to help businesses optimize their technology stack, streamline processes, and drive innovation... Read More →',
      link: '/Valoores103A/Consultancy'
    }
  ];
  return (
    <>
      <div className="title_business mt-5" id="business_scroll">
          <div className="container">
              <div className="row d-flex flex-row mb-3">
                    <h2>
                      <span>VALOORES in'Group: </span>
                      Solutions
                    </h2>
                    <p>VALOORES provides clean, clear and classy data and unparalleled insight and analytics through tools tailored to your workflow so you can move from big ideas to big results.</p>
                    {business.map((slide, index) => (
                      <div className="col-md-4 my-4 flex-display" key={index}>
                        <div className='business__card-container text-center'>
                          <Link target="_blank"  to={slide.link}>
                            <img src={slide.image} alt="1" />
                          </Link>
                          <Link target="_blank"  to={slide.link}>
                            <h4 className='fw-bold mb-3'>{slide.title}</h4>
                          </Link>
                          <p>{slide.description}</p>
                        </div>
                      </div>
                    ))}
              </div>
          </div>
      </div>
    </>
  )
}

export default Business