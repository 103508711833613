const InBalanceSheetPlanning = [
    {
      title: "Overview",
      desc: 
      (
        <>
         VALOORES Financial services’ in’Balance Sheet exposes a detailed form of all activities related to subordinates, branches, head office and affiliates, allowing professional decisions relying on viable true presentation of your position. In’Balance Sheet allows a business to save time and money. This solution makes your balance sheet easily accessible and available giving you the instant information needed to facilitate business decisions without spending useful time or money. In’Balance Sheet provides the business with full customization capabilities in almost every aspect of the balance sheet, showing the information you need for day-to-day operations. Furthermore, balance sheets are updated in real time, giving you up-to-date information.
        </>
      ),
      list: ['']
    },
    {
      title: 'Key Benefits',
      desc: '',
      list: [
        'Net Interest Margin and balance sheet forecasts using cash flow-based planner',
        'Integration of Risk, Performance Management, Customer Insight, and Financial Compliance',
        'Cash flow processing for current and new book of business',
      ]
    },
    {
      title: 'features',
      desc:'',
      list:[
        'Accurately plan business unit level margin and profit with common cash flow engines shared with Valoores ALM and FTP applications',
        'Determine accurate current position due to customer relationship level details',
        'Deliver actionable insight across multiple dimensions',
        'Implement as a stand-alone application or fully integrate with ALM and FPT'
      ]
    },
    {
      title: 'Case Studies',
      desc:'',
      list:[
        `Regardless of industry, most budgeting exercises focus the majority of time and effort on expense forecasting. 
      While this is also true for banking, the nature of the banking business imposes unique and difficult planning challenges. 
      Banks must carefully forecast:
      <ul>
        <li>The balance sheet itself</li>
        <li>Interest Income and Interest Expense associated with a given Balance Sheet over a budget horizon</li>
        <li>The non-interest income (fees) that the Balance Sheet will produce</li>
        <li>The non-interest expense, including both Operating Expenses and Capital Expenditures that will be required to support the Balance Sheet</li>
      </ul>`,
        'Valoores Financial Services Balance Sheet Planner allows product planner managers to simultaneously forecast both new business volumes and the margins. Using the Valoores Financial Services Analytical Applications cash flow engine and instrument-level data model, Valoores Financial Services Balance Sheet Planner generates detailed current position runoff data for your entire institution.',
        'From there, you can plan new product volumes and spreads at the product and planner center level and generate cash flow projections for your forecasts. Valoores Financial Services Balance Sheet Planner combines your current position runoff and spreads with your new business runoff and spreads allowing you to gain a complete picture of balances and spreads and a wealth of related cash flow information for each budgeting period per product.',
        'Valoores Financial Services Balance Sheet Planner improves Balance Sheet and margin planner by utilizing a sophisticated cash flow engine. Valoores Planner is used by theapplication to generate customized forms and business rules to leverage cash flow data in forecasting non-cash flow based planner accounts, e.g., non-interest income, non-interest expense,reserve accounts, etc. In addition, seeded forms are utilized for forecasting Deposit Services Charges and Loan Fees. Valoores Financial Services Balance Sheet Planner extrapolates additional planner elements based on cash flow runoff (e.g. service fees, annual fees, origination expenses).',
        'The end result is an unparalleled planner solution for financial services institutions, supporting creation of complete and accurate plans in all areas of the balance sheet and income statement.'
      ]
    }
  ];
  
  export default InBalanceSheetPlanning;
  