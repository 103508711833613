import React from 'react'
import InvestArray from '../InvestArray';  
import {Fade } from 'react-awesome-reveal';

function Investigatory() {
  return (
    <div className="container">
        <div className="center-div">
            <h2 className="vcms-heading w-75" style={{ fontSize: '36px', textAlign: 'center' }}>
            <Fade direction='up' >
            Investigatory Aspects - Enterprise Pain Point Analysis and Resolution Framework
            </Fade>
            </h2>
        </div>
            {InvestArray.map((invest, index) =>(
            <div className='row justify-content-center'>
                <div className={`${index %2 === 0 ? 'order-1' : 'order-2'} col-md-6 p-0 m-0 d-flex flex-column align-items-center justify-content-center`}>
                    <div className="d-flex flex-column w-75 p-0 m-0" > 
                        <Fade direction='up' >  
                        <h2>{invest.title}</h2>
                        <h4 className="fw-normal mt-3 mb-0" style={{lineHeight:'2rem'}}>{invest.desc}</h4>
                        </Fade>
                    </div>
                </div>
                    <div className={`${index %2 === 0 ? 'order-2' : 'order-1'}  col-md-6 p-0 d-flex flex-column`} style={{height:'auto'}}>
                    <Fade direction={index %2 === 0 ? 'right' : 'left'} cascade>
                        <div className="vcms-image vcms-image-box1" style={{height:'auto', overflow: 'hidden'}}>
                            <img src={invest.img} className='w-100' alt="" />
                        </div>
                    </Fade>
                </div>
            </div>
            ))}
        </div>
  )
}

export default Investigatory