import React, {useEffect} from 'react'
import Sidebar from '../Banking/BankingPages/SideMenu'
import Banner from './Banner'
import Content from './Content'
import { Helmet } from 'react-helmet';
import {useLocation} from 'react-router-dom';
import inProfit1 from '../../img/inprofit/inprofit1.jpg';
import inProfit2 from '../../img/inprofit/inprofit2.jpg';
import inProfit3 from '../../img/inprofit/inprofit3.jpg';
import inProfit4 from '../../img/inprofit/inprofit4.jpg';
import inProfit5 from '../../img/inprofit/inprofit5.jpg';
import inProfit6 from '../../img/inprofit/inprofit6.jpg';
import inProfit7 from '../../img/inprofit/inprofit7.jpg';
import inProfit8 from '../../img/inprofit/inprofit8.jpg';
import inProfit9 from '../../img/inprofit/inprofit9.jpg';
import AmlArray from './content/InBalanceSheet'
import CashFlowArray from './content/InCashFlow'
import inFundsArray from './content/InFunds'
import inRevenueArray from './content/InRevenue'
import inWealthManagement from './content/InWealthManagement'
import inRatios from './content/InRatios'
import inBranch from './content/InBranch'
// import BalancePlan from './content/InBalanceSheetPlanning'
import ComeArray from './content/IncomeStatement'
import InDebtCollection from './content/InDebtCollection';
import InAssetManagement from './content/InAssetManagement';
import InBalanceSheetPlanning from '../Banking/BankingPages/content/InBalanceSheetPlanning';

const Feature = () => {
    const location = useLocation();
    const currentURL = window.location.href;
    const searchParams = new URLSearchParams(location.search);
    const receivedVariable = searchParams.get('variable');
    console.log(receivedVariable);
    let title, BannerImg, BannerDesc, RiskArray, overview, demo, pdf, fact, paper;    

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [receivedVariable]);

    switch(receivedVariable){
        case 'DebtCollection':
            title = "in'Debt Collection And Recovery"
            BannerImg = inProfit1
            BannerDesc = "”VALOORES in'Debt Collection and Recovery enables collection departments to manage and control all stages of the collection process. Its primary goal is to help banks improve the recovered amounts while optimizing collection costs.”"
            RiskArray = InDebtCollection
            overview = 'yes'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'BalanceSheet':
            title = "in'Balance Sheet"
            BannerImg = inProfit2
            BannerDesc = "“Big data has not only changed the way that banks address AML, but has also heightened the expectations of the regulators.”"
            RiskArray = AmlArray
            overview = 'yes'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'BalanceSheetPlanning':
            title = "in'Balance Sheet Planning"
            BannerImg = inProfit3
            BannerDesc = "“VALOORES Financial Services Balance Sheet Planner enables the delivery of accurate margin forecasts and comprehensive, meaningful budgets.”"
            RiskArray = InBalanceSheetPlanning
            overview = 'yes'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'IncomeStatement':
            title = "in'Income Statement"
            BannerImg = inProfit4
            BannerDesc = "“in’Income Statement organizes all of your accounting data in one place, making it easy to create an accurate income statement.”"
            RiskArray = ComeArray
            overview = 'yes'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'InCashFlow':
            title = "in'Cash Flow"
            BannerImg = inProfit5
            BannerDesc = "“in'Cash Flow reduces the time and costs involved in managing financial planning at divisional, corporate and group levels.”"
            RiskArray = CashFlowArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'InAssetManagement':
            title = "in'Asset Liability Management"
            BannerImg = inProfit6
            BannerDesc = "“in'Asset Liability Management, a framework that includes sophisticated concepts such as duration matching, variable rate pricing, and the use of static and dynamic simulation.”"
            RiskArray = InAssetManagement
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'InFunds':
            title = "in'Funds Transfer Pricing"
            BannerImg = inProfit6
            BannerDesc = "“in'Funds Transfer Pricing enables accurate assessment of profitability along product, channel,and business lines, as well as the centralizationof interest rate risk for effective management.”"
            RiskArray = inFundsArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'InRevenue':
            title = "in'revenue Management and Billing"
            BannerImg = inProfit7
            BannerDesc = "“Service providers globally rely on in'Revenue Management and Billing to build strong brands and improve profitability.”"
            RiskArray = inRevenueArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'InWealthManagement':
            title = "in'Wealth Management"
            BannerImg = inProfit8
            BannerDesc = "“With in'Wealth Management, banks can plan, record, track and manage the overall wealth of a customer across a range of asset classes and instruments.”"
            RiskArray = inWealthManagement
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'InRatios':
            title = "in'Ratios"
            BannerImg = inProfit9
            BannerDesc = "“in'Ratios measure companies’ operational efficiency, liquidity, stability and profitability, giving investors more relevant information than raw financial data.”"
            RiskArray = inRatios
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'InBranch':
            title = "in'Branch"
            BannerImg = inProfit9
            BannerDesc = "“in’Branch Profitability allows you to manage branch activities which have a direct impact on profitability (revenues and expenses).”"
            RiskArray = inBranch
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        // case 'baselregulatory':
        //     title = "in'Basel Regulatory Capital"
        //     BannerImg = inProfit7
        //     BannerDesc = "in'Basel III solution enables quick compliance covering Basel III guidelines on capital ratios, capital buffer, credit valuation adjustment liquidity and leverage ratios"
        //     RiskArray = baselCapitalArray
        //     overview = 'no'
        //     demo = 'no'
        //     pdf = 'no'
        //     fact = 'no'
        //     paper = 'no'
        // break;

        // case 'liquidityriskmanagement':
        //     title = "in'Liquidity Risk Management"
        //     BannerImg = inProfit7
        //     BannerDesc = ""
        //     RiskArray = liquidityArray
        //     overview = 'no'
        //     demo = 'no'
        //     pdf = 'no'
        //     fact = 'no'
        //     paper = 'no'
        // break;

        // case 'riskandbusinessprocessconsulting':
        //     title = "in'GRC - Risk & Business Process"
        //     BannerImg = inProfit9
        //     BannerDesc = ""
        //     RiskArray = grcArray
        //     overview = 'no'
        //     demo = 'no'
        //     pdf = 'no'
        //     fact = 'no'
        //     paper = 'no'
        // break;

        // case 'enterpriseincidentmanagement':
        //     title = "in'Enterprise Incident Management"
        //     BannerImg = inProfit8
        //     BannerDesc = ""
        //     RiskArray = enterpriseArray
        //     overview = 'no'
        //     demo = 'no'
        //     pdf = 'no'
        //     fact = 'no'
        //     paper = 'no'
        // break;

        // case 'operationalrisk':
        //     title = "in'Operational Risk"
        //     BannerImg = inProfit8
        //     BannerDesc = "in'Operational Risk, a simple to use, comprehensive out-of the-box solution helps financial institutions quickly adopt and practice effective enterprise GRC"
        //     RiskArray = operationArray
        //     overview = 'no'
        //     demo = 'no'
        //     pdf = 'no'
        //     fact = 'no'
        //     paper = 'no'
        // break;

        // case 'itgovernance':
        //     title = "in'IT Governance"
        //     BannerImg = inProfit9
        //     BannerDesc = "in'IT Governance offers an advanced and comprehensive IT GRC software solution for streamlining IT GRC processes, effectively managing IT risk, and meeting IT regulatory requirements."
        //     RiskArray = itArray
        //     overview = 'no'
        //     demo = 'no'
        //     pdf = 'no'
        //     fact = 'no'
        //     paper = 'no'
        // break;
        default:
        break;
        }

        // const formattedPage = receivedVariable.replace(/-/g, ''); // Remove dashes from the page variable
        // const formattedUrl = `/banking/riskandcompliance/${formattedPage}`;
  
        // window.history.replaceState(null, null, formattedUrl);
    
    return (
        <>
            <Helmet>
                <title>{receivedVariable}</title>
            </Helmet>
            <Banner
                img = {BannerImg}
                desc= {BannerDesc}
                demo = {demo}
                pdf = {pdf}
                fact = {fact}
                paper = {paper}
            />
            <div className='section_divider risk_container my-4'>
                <div className="container">
                    <h4>{title}</h4>
                </div>
            </div>
            <div className='d-flex flex-row container'>
                <div className="main-content overview_content" style={{flex: '4'}}>
                    
                    <Content RiskArray = {RiskArray}/>
                </div>

                <div className="sidebar_section" style={{flex: '1'}}>
                    <Sidebar currentURL={currentURL}/> 
                </div>
            </div>
            {receivedVariable === 'Aml' && <>
                {AmlArray.map((item, index) => (
                    <div>
                    {item.transaction.map((trans, transIndex) => (
                        <div key={transIndex}>
                            {trans.paralax === 'yes' && <div className='paralax_trans d-flex align-items-center justify-content-center mb-4' style={{ backgroundImage: `url(${trans.img})`, height: '25rem' }}>
                                <h4 className='text-white w-75 text-center fw-bold' style={{lineHeight: '2.5rem'}}>“VALOORES Financial Crime & Compliance suite of applications helps financial institutions tackle today's tactical risk & compliance problems.”</h4>    
                            </div>}
                        </div>
                    ))}
                    <div className="container mb-5">
                        <div className="row">
                            <div className="col-md-6">
                               <h5 className='mb-4' style={{color: '#007ac1'}}> Key Benefits</h5>
                                <ul className='marker-blue'>
                                    {item.benefits.map((benef, benefIndex) => (
                                        <li key={benefIndex}>{benef}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <h5 className='mb-4' style={{color: '#007ac1'}}>Key Features</h5>
                                <ul className='marker-blue'>
                                    {item.features.map((feat, featIndex) => (
                                        <li key={featIndex}>{feat}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    </div>
                ))}
            </>}
            
        </>
    )
}

export default Feature