import React from 'react'
import './Banner.css'
// import { Fade } from 'react-awesome-reveal';
import 'animate.css';

const Banner = ({title, desc, descSize, img, classType = '', overlay}) => {
  return (
    <div className='banner_info' style={{backgroundImage: `url(${img})`}}>
        { overlay !== 'off' && <div className="overlay"></div> }
        <div className={`text_content-banner text-white ${classType}`}>
          {/* <Fade left> */}
          <div className='animate__animated animate__fadeInLeft'>
            <h1 className='font--poppins text-white'>{title}</h1>
            <h4 className='font--poppins' style={{fontSize: `${descSize}`}}>{desc}</h4>
          {/* </Fade> */}
          </div>
        </div>
    </div>
  )
}

export default Banner