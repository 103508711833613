import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Vcms.css';
import Carouselblog from './components/Carouselblog';
import Introduction from './components/Introduction';
import THreeImg from './components/THreeImg';
import Investigatoryblog from './components/Investigatoryblog';
import ComplianceAspects from './components/ComplianceAspects';
import Intro from "../../admin/IntroServices/Introduction";


const Vcms = () => {

  return (
    <div>
        <Carouselblog/>
        <Intro title="V-CMS"/>
        <Introduction/>
        <THreeImg/>
        <Investigatoryblog/>
        <ComplianceAspects/>
    </div>
  );
};

export default Vcms;
